'use client'
import { Provider, ErrorBoundary } from '@rollbar/react'
import Rollbar, { LogArgument } from 'rollbar'
import { getCookie } from './getCookie'

const baseConfig = {
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NEXT_PUBLIC_SERVER_ENV,
  enabled: process.env.NODE_ENV !== 'development',
  version: process.env.NEXT_PUBLIC_RELEASE_TAG,
  payload: {
    client: {
      javascript: {
        guest_id: getCookie('guest_id_jisr'),
        source_map_enabled: true,
        code_version: process.env.NEXT_PUBLIC_RELEASE_TAG,
        guess_uncaught_frames: true,
      },
    },
  },
  checkIgnore: (isUncaught: boolean, args: LogArgument, payload: any) => {
    const frames: { filename: string }[] = payload.body?.trace?.frames || []
    if (
      frames.filter(i => i.filename?.includes('chrome-extension://')).length
    ) {
      return true
    }

    if (typeof args === 'object' && Array.isArray(args)) {
      if (
        args?.[1]?.response ||
        args?.[1]?.message?.includes('Network Error')
      ) {
        return true
      }
    }

    return false
  },
}

export const clientConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  ...baseConfig,
}
export const rollbar = new Rollbar(clientConfig)

export const RollbarProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <Provider config={clientConfig}>
      {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  )
}
